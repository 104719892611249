import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { format, differenceInCalendarMonths } from 'date-fns';

import Input from 'components/Input';
import Dropdown from 'components/Input/Dropdown';
import Button from 'components/Button';
import { CurrencyInput } from 'components/masks';
import { Form } from './styles';
import api from 'services/api';
import newApi from 'services/newApi';
import { formatDate } from 'utils/dates';

export default function Installment() {
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});
  const [selectItem, setSelectItem] = useState({});
  const [start, setStart] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [description, setDescription] = useState('');
  const [value, setValue] = useState(0);
  const [installments, setInstallments] = useState(1);
  const [loading, setLoading] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/users/${id}/categories`, {
        params: {
          type: 'spendings',
          items: true,
        },
      });

      setCategories(
        data.map(category => ({
          value: category?.name,
          label: category?.name.toUpperCase(),
          id: category?.id,
          ...category,
        }))
      );
    }
    fetchData();
  }, [id]);

  function loadItems() {
    if (!selectCategory.items) return [];

    return selectCategory?.items.map(item => ({
      value: item.name,
      label: item.name.toUpperCase(),
      ...item,
    }));
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);

      if (installments < 1) {
        return toast.error('Vezes da parcela deve ser maior que 1');
      }

      const [year, month, day] = start.split('-')
      const formattedStart = formatDate(year, month, day)

      const currentInstallment =
        Math.abs(differenceInCalendarMonths(new Date(), new Date(formattedStart))) + 1;

      const totalInstallments = installments;

      await api.post(`/users/${id}/v2/installments`, {
        name: description,
        value: value / installments,
        category_id: selectCategory.id,
        item_id: selectItem.id,
        currentInstallment: 1,
        totalInstallments: parseFloat(installments),
        start: formattedStart,
      });

      setValue(0);
      toast.success('Lançamento realizado com sucesso');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Houve um erro com o lançamento');
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Dropdown
        label="Nome da categoria"
        placeholder="Categoria..."
        width="100%"
        options={categories}
        onChange={selected => setSelectCategory(selected)}
      />
      <Dropdown
        label="Nome do item"
        placeholder="Item..."
        width="100%"
        options={loadItems()}
        onChange={select => setSelectItem(select)}
      />
      <Input
        label="Data de inicio"
        type="date"
        value={start}
        onChange={e => setStart(e.target.value)}
      />

      <Input
        label="Descrição"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />

      <CurrencyInput
        label="Valor"
        prefix="R$ "
        value={value}
        onChange={cleanValue => setValue(cleanValue)}
      />

      <Input
        label="Vezes"
        value={installments}
        type="number"
        onChange={e => setInstallments(e.target.value)}
      />

      <div className="buttons">
        <Button type="submit" stripped color="info">
          {loading ? 'Carregando...' : 'Salvar transação'}
        </Button>
      </div>
    </Form>
  );
}
