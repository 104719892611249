/* eslint-disable import/no-cycle */
import axios from 'axios';
import { store } from 'store';
import { signOut } from 'store/modules/auth/actions';

import history from 'services/history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3333',
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  error => {
    console.error(error.response);
    const access_token = localStorage.getItem("persist:realeasy/client");
    if (error.response.status === 401 && access_token) {
      history.push('/sign_out');
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

/*api.interceptors.response.use(
  response => response,
  err => {
    if (
      (err.response.status === 401 &&
        err.response.data.error.name === 'InvalidJwtToken') ||
      err.response.data.error.name === 'ExpiredJwtToken'
    ) {
      history.push('/sign_out');
      store.dispatch(signOut());
    }
    return Promise.reject(err);
  }
);*/

export default api;
